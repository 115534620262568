import React, { useReducer } from 'react';
import { useRouter } from 'next/router';

import { createDate, getValidDateRange } from 'utils/date';
import { Router, useTranslation } from 'src/i18n';
import route from 'constants/routes';
import { PERFORMANCE_TYPES, search_types } from 'constants/search';
import LocationField from 'components/Globals/FormFields/LocationField';
import AggregationSearchField from 'components/Globals/FormFields/AggregationSearchField';

import { FILTER_AGGREGATION_SEARCH, PRODUCTIONS } from 'constants/endpointConstants';

import { TP, ENTITY_TYPE, PRODUCTION_VALIDITY_IDENTIFIERS, AGGREGATION_TYPES } from 'constants/index';
import EntityName from 'components/Globals/EntityName';
import { getWorkComposers, getWorkTitle } from 'utils/works';
import classes from './PerformanceSearch.module.scss';

import DatePerformanceSearchFields from './DatePerformanceSearchFields';
import SearchContentWrapper from '../../common/SearchContentWrapper';

const performanceSearchState = {
  country: null,
  city: null,
  organization: '',
  composer: '',
  musicalWork: '',
  startDate: '',
  endDate: '',
};

function performanceSearchReducer(state, action) {
  switch (action.type) {
    case PERFORMANCE_TYPES.LOCATION:
      return { ...state, ...{ country: action?.payload?.country, city: action?.payload?.city } };
    case PERFORMANCE_TYPES.ORGANIZATION:
      return { ...state, organization: action.payload };
    case PERFORMANCE_TYPES.COMPOSER:
      return { ...state, composer: action.payload, musicalWork: null };
    case PERFORMANCE_TYPES.MUSICAL_WORK:
      return { ...state, musicalWork: action.payload, composer: { name: getWorkComposers(action.payload) } };
    case PERFORMANCE_TYPES.START_DATE:
      return { ...state, startDate: action.payload };
    case PERFORMANCE_TYPES.END_DATE:
      return { ...state, endDate: action.payload };
    case PERFORMANCE_TYPES.RESET:
      return { ...performanceSearchState };
    default:
      throw new Error();
  }
}

const PerformanceSearch = ({ activeTab, isClearText, styles, showConcludingBlock, trackingData = {} }) => {
  const router = useRouter();
  const isPerformancePage = router.pathname?.includes(search_types.PRODUCTIONS?.toLowerCase());
  const { country_id, city_id, work_creator_id, work_id, organization_id, date_from, date_to } = isPerformancePage
    ? router?.query
    : {};
  const countryID = country_id ? Number(country_id) : null;
  const cityID = city_id ? Number(city_id) : null;
  const [state, dispatch] = useReducer(performanceSearchReducer, performanceSearchState, () => ({
    ...(countryID && { country: { value: countryID, id: countryID } }),
    ...(cityID && { city: { value: cityID, id: cityID } }),
    ...(work_creator_id && { composer: { value: work_creator_id, id: work_creator_id } }),
    ...(work_creator_id && { composer: { value: work_creator_id, id: work_creator_id } }),
    ...(work_id && { musicalWork: { value: work_id, id: work_id } }),
    ...(organization_id && { organization: { value: organization_id, id: organization_id } }),
    ...(date_from && { startDate: createDate(date_from, 'YYYY-MM-DD') }),
    ...(date_to && { endDate: createDate(date_to, 'YYYY-MM-DD') }),
  }));
  const { t } = useTranslation(['NS_DISPLAY_V4', 'NS_APP_GLOBALS']);

  const handleReset = () => {
    dispatch({ type: PERFORMANCE_TYPES.RESET });
  };

  const handleSearch = () => {
    const { dayFrom, dayTo } = getValidDateRange(state.startDate, state.endDate);
    const query = {
      is_producer_approved: true,
      approved_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG,
      date_from: dayFrom,
      date_to: dayTo,
      ...(state.country?.id && { country_id: state?.country?.id }),
      ...(state.city?.id && { city_id: state?.city?.id }),
      ...(state.organization?.id && { organization_id: state?.organization?.id }),
      ...(state.composer?.id && { work_creator_id: state?.composer?.id }),
      ...(state.musicalWork?.id && { work_id: state?.musicalWork?.id }),
    };

    Router.push({
      pathname: `${route.PRODUCTIONS}/`,
      query,
    });
  };

  const fieldConfig = [
    {
      Component: LocationField,
      entityType: ENTITY_TYPE.PRODUCTION,
      onChange: (e, payload) => dispatch({ type: PERFORMANCE_TYPES.LOCATION, payload }),
      country: state?.country,
      city: state?.city,
      showCityField: true,
      hasAll: false,
      fullWidth: true,
    },
    {
      Component: AggregationSearchField,
      value: state?.organization,
      type: PERFORMANCE_TYPES.ORGANIZATION,
      params: {
        endpoint: FILTER_AGGREGATION_SEARCH(PRODUCTIONS),
        enableQuery: !!state.organization?.id,
        queryParams: {
          aggregation_type: AGGREGATION_TYPES.ORGANIZATION,
          has_productions: 'yes',
          aggregation_query: state?.organization?.label,
          approved_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG,
          is_producer_approved: true,
          country_id: state.country?.id,
          city_id: state.city?.id,
          organization_id: state.organization?.id,
        },
      },
      onChange: value => dispatch({ type: PERFORMANCE_TYPES.ORGANIZATION, payload: value }),
      label: t(`NS_APP_GLOBALS:${TP}.FN_ORGANIZATIONS`),
      fullWidth: true,
      renderMenuListItem: (props, option) => (
        <li {...props}>
          <EntityName entity={option} entityType={ENTITY_TYPE.ORGANIZATION} isRaw trackingData={trackingData} />
        </li>
      ),
    },
    {
      Component: AggregationSearchField,
      value: state?.composer,
      type: PERFORMANCE_TYPES.COMPOSER,
      params: {
        endpoint: FILTER_AGGREGATION_SEARCH(PRODUCTIONS),
        enableQuery: !!state.composer?.id,
        queryParams: {
          aggregation_type: 'work_creator_id',
          work_creator_profession: 'composer',
          has_productions: 'yes',
          aggregation_query: state?.composer?.label,
          approved_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG,
          is_producer_approved: true,
          country_id: state.country?.id,
          city_id: state.city?.id,
          work_creator_id: state.composer?.id,
          organization_id: state.organization?.id,
          work_id: state.musicalWork?.id,
        },
      },
      onChange: value => dispatch({ type: PERFORMANCE_TYPES.COMPOSER, payload: value }),
      label: t(`${TP}.m_COMPOSER`),
      fullWidth: true,
      renderMenuListItem: (props, option) => (
        <li {...props}>
          <EntityName entity={option} entityType={ENTITY_TYPE.ARTIST} isRaw trackingData={trackingData} />
        </li>
      ),
    },
    {
      Component: AggregationSearchField,
      value: {
        ...state?.musicalWork,
        ...(state?.musicalWork?.id && { name: getWorkTitle(state?.musicalWork, true, true) }),
      },
      type: PERFORMANCE_TYPES.MUSICAL_WORK,
      params: {
        endpoint: FILTER_AGGREGATION_SEARCH(PRODUCTIONS),
        enableQuery: !!state.musicalWork?.id,
        queryParams: {
          aggregation_type: 'work_id',
          aggregation_query: state?.musicalWork?.label,
          approved_by: PRODUCTION_VALIDITY_IDENTIFIERS.ADMIN_AND_ORG,
          is_producer_approved: true,
          countryId: state.country?.id,
          city_id: state.city?.id,
          organization_id: state.organization?.id,
          work_creator_id: state.composer?.id,
          work_id: state.musicalWork?.id,
        },
      },
      onChange: value => {
        dispatch({ type: PERFORMANCE_TYPES.MUSICAL_WORK, payload: value });
      },
      label: t(`${TP}.m_MUSICALWORK`),
      fullWidth: true,
      renderMenuListItem: (props, option) => (
        <li {...props}>
          <EntityName
            entity={option}
            entityType={ENTITY_TYPE.WORK}
            withComposers
            onlyShortName
            trackingData={trackingData}
          />
        </li>
      ),
    },
  ];

  return (
    <SearchContentWrapper
      activeTab={activeTab}
      onReset={handleReset}
      onSearch={handleSearch}
      linkProps={{ href: route.PRODUCTIONS, as: route.PRODUCTIONS }}
      isClearText={isClearText}
      styles={styles}
      disabled={Object.keys(state)?.length === 0}
      showConcludingBlock={showConcludingBlock}
      trackingData={trackingData}
    >
      <div className={classes.entityWrap}>
        {fieldConfig.map(({ Component, ...props }, index) => (
          <Component key={index} {...props} />
        ))}
      </div>
      <div className={classes.actionsButtonWithDate}>
        <DatePerformanceSearchFields
          state={state}
          setStartDate={value => dispatch({ type: PERFORMANCE_TYPES.START_DATE, payload: value })}
          setEndDate={value => dispatch({ type: PERFORMANCE_TYPES.END_DATE, payload: value })}
          className={classes.actionsButtonWithDate__datePicker}
        />
      </div>
    </SearchContentWrapper>
  );
};

export default PerformanceSearch;
